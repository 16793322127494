import {Component, OnInit} from '@angular/core';
import {HeaderTextService} from '../../../services/header-text.service';
import {ShowSidebarService} from '../../../services/show-sidebar.service';
import {AuthWebservice} from '../../../services/webservices/auth.webservice';
import {environment} from '../../../../environments/environment';
import {Destroyed} from '../directives/destroyed.directive';
import {MatToolbarModule} from '@angular/material/toolbar';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: true,
  imports: [MatToolbarModule, NgClass]
})
export class ToolbarComponent extends Destroyed implements OnInit {
  public title = '';
  public url = '';
  public dateBuild = '';
  public version = '';
  public isRed: boolean = false;

  constructor(
    private readonly headerTextService: HeaderTextService,
    private readonly showSidebarService: ShowSidebarService,
    public authService: AuthWebservice
  ) {
    super();
    this.dateBuild =
      environment.timestamp === '#{timestamp}' ? 'Développement' : environment.timestamp;
  }

  ngOnInit() {
    this.headerTextService.title.pipe(this.untilDestroyed()).subscribe((title) => {
      this.title = title;
    });

    this.headerTextService.isRed.pipe(this.untilDestroyed()).subscribe((isRed) => {
      this.isRed = isRed;
    });
  }

  showSideBar() {
    this.showSidebarService.showSidebar = !this.showSidebarService.showSidebar;
  }
}
